export const apiEndPoints = {
	TRADEBOX: {
		ALL_STRATEGY: '/api/tradebox/strategy/filter/analyst/iifl',
		ONE_STRATEGY: '/api/tradebox/strategy/',
		LATEST_BASKET: '/api/tradebox/basket/',
		MARKET_FEED: '/api/tradebox/marketfeed/ltp',
		BUILD_PAYOFF: '/api/tradebox/payoff/builder',
		CREATE_BASKET: '/api/tradebox/basket',
		CREATE_TRADEBOX: '/api/tradebox/strategy',
		EXIT_BASKET: '/api/tradebox/basket/exit',
		ADJUST_TRADEBOX: '/api/tradebox/basket/',
		SCRIPT_MASTER: '/api/scriptmaster',
		POSTHOG_TREND: '/api/posthog/trend',
	},
	SOLO: {
		FILTER_SOLOS: '/filter_solos',
		FILTER_ORDERS: '/filter_orders',
		ADD_SOLO: '/post_solo',
		IS_EXIST: '/is_solo_exist',
		GET_SOLO_BY_ID: '/get_solos_by_id',
		CLOSE_SOLO: '/deactivate_solos',
		EDIT_SOLO: '/solo/edit_solos',
		SEARCH: '/signals_auto_suggestion',
		SOLO_SUMMARY: '/get_solo_insight',
		FILTER_VALUE: '/get_filter_values',
	},
	COGNITO: {
		GET_ALL_USERS: '/get_users',
	},
	PRICE_FEED: {
		OHLC: '/get_market_feed',
		WS_CREDENTIALS: '/get_websocket_parameter',
	},
	DASHBOARD: {
		ORDERS_MIS: '/mis/orders/days'
	}
} as const;
