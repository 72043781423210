import { AuthenticationDetails, CognitoUser, CognitoUserPool, CookieStorage } from 'amazon-cognito-identity-js';
import { parseCookies } from 'nookies';
import { parseJwt } from './text';

export const cognitoDomain = process.env.NEXT_PUBLIC_COGNITO_DOMAIN;

export const cognitoUserPool = new CognitoUserPool({
	UserPoolId: process.env.NEXT_PUBLIC_COGNITO_POOL_ID,
	ClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID,
	Storage: new CookieStorage({ domain: cognitoDomain, expires: 1 }),
});

export const cognitoUser = (username) => new CognitoUser({
	Username: username,
	Pool: cognitoUserPool,
	Storage: new CookieStorage({ domain: cognitoDomain, secure: process.env.NODE_ENV === 'production' }),
});

export const cognitoAuthDetails = (username, password) => new AuthenticationDetails({
	Username: username,
	Password: password,
});

export const getCognitoUserToken = () => {
	let accessToken = null;
	const user = cognitoUserPool.getCurrentUser();

	if (user) {
		user.getSession((err, session) => {
			if (err) {
				alert(err.message || JSON.stringify(err));
				return { accessToken: accessToken };
			} else {
				accessToken = session.getIdToken().getJwtToken();
			}
		});
	}
	return { accessToken };
};


export const getUserEmail = () => {
	return parseJwt(getCognitoUserToken()['accessToken'])['email']
}

export const getCognitoSessionCookies = (req = null) => {
	const cookies = parseCookies({ req });
	return Object.keys(cookies).filter(item => item.includes('CognitoIdentityServiceProvider'));
};

export const isCognitoSessionCookieValid = (req = null) => {
	return getCognitoSessionCookies(req).length >= 5;
};
