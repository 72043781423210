import { useEffect, useState } from 'react';
import { parseJwt, parseNameFromEmail } from '../utils/text';
import { getCognitoUserToken } from '../utils/cognito';
import { useRouter } from 'next/router';

const useGetUserDetails = () => {

	const router = useRouter()
	const [details, setDetails] = useState({
		email: '',
		name: '',
		admin: false,
		groups: [],
		loginTime: 0,
	});

	useEffect(() => {

		const token = getCognitoUserToken()['accessToken'];
		if (token) {
			const parsed = parseJwt(token);
			setDetails({
				email: parsed.email,
				name: parseNameFromEmail(parsed.email),
				admin: parsed['cognito:groups'].includes('Admin'),
				groups: parsed['cognito:groups'],
				loginTime: parsed.auth_time,
			});

		}
	}, [router.pathname]);

	return details;

};

export default useGetUserDetails;