import { useRouter } from 'next/router';
import { useToast } from '@chakra-ui/react';
import { cognitoUserPool, getCognitoSessionCookies } from '../utils/cognito';
import { destroyCookie } from 'nookies';

const useLogout = () => {
	const router = useRouter();
	const toast = useToast();

	return (error?: any) => {
		const toastId = 'logout';
		const user = cognitoUserPool.getCurrentUser();

		if (user) {
			user.signOut();
		} else {
			const cookies = getCognitoSessionCookies();
			cookies.forEach(key => {
				destroyCookie(null, key);
			});
		}

		router.push('/').then(r => {
			if (!toast.isActive(toastId)) {
				toast({
					id: 'logout',
					title: 'You have been logged out',
					status: 'info',
				});
			}
		});
	};
};

export default useLogout;